
/* bg dark = #212529 */

/* invert #fffdf8 */
html,
body {
  margin: 0;
  padding: 0;
  height: auto;
  min-height: 100%; /* needed for container min-height */
}

#root {
    position: relative;
    height: auto !important; /* real browsers */
    min-height: 100%; /* real browsers */
    overflow-x: hidden;
}

:root {
    --primary: #0b3d91;
    --primary-light: #4dacff;
    --secondary: #ffc107;
    --dark: #212529;
    --light: #f8f9fa;
    --text-dark: #333;
    --text-light: #f8f9fa;
    --overlay-dark: rgba(0, 0, 0, 0.7);
    --overlay-light: rgba(255, 255, 255, 0.1);
    --transition: all 0.3s ease;
    --shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    --border-radius: 8px;
  }

body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    line-height: 1.6;
    color: var(--text-dark);
    background-color: var(--light);
  }

#main {
    height: 100% !important;
    min-height: 100vh!important;
    position: relative;
    margin: auto;
}

#main > container {
    min-height: 800px !important;
}
#home-container{
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
}
.event-row {
    justify-content: center;
}

#login-nav {
    color: white !important;
}
.max-width-960px {
    max-width: 960px !important;
}

.max-width-1 {
    max-width: 1080px !important;
}
.events-menu-items{
    width: auto !important;
}

.events-menu-btn {
    width: auto;
    border-radius: 9999px !important;
}
#footer {
    background-color: #000;
    box-shadow:  0rem -0.075rem rgba(12,14,28,.08);
    position: absolute;
    display: block;
    right: 0;
    left: 0;
    padding-top: 20px;
}

.navbar-custom {
    color: black !important;
}
.mbnd-svg {
    color: inherit !important;
}

.my-nav-link {
    border-radius: 3% !important;
    width: 100px;
}
.calendar-item {
    color: blue !important;
}
.events-menu-item:hover {
    box-shadow: inset 100px 100px 0 0 #f4f4f4;
    border-radius: 2%;
    font-weight: 700;
}
.round-btn {
    width: 12rem;
}
.breadcrumb {
    margin-bottom: 0px !important;
}
.h100 {
    position: relative !important;
    height: 100% !important;
}
ƒe

.h50 {
    height: 50% !important;
}
.mt-10 {
    margin-top: 10px !important;
}

.mt-20 {
    margin-top: 20px !important;
}


.mt-30 {
    margin-top: 30px !important;
}


.mt-50 {
    margin-top: 50px !important;
}

.mt-80 {
    margin-top: 80px !important;
}


.mt-100 {
    margin-top: 100px !important;
}

.mt-150 {
    margin-top: 150px !important;
}
.mb-80 {
    margin-bottom: 80px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.fixed-top-menu > img {
    position: relative;
    top: -7px
}
.fixed-top-menu {
    display: block;
    position: relative;
    width: auto;
    padding: 0px !important;
    margin: 0 !important;
}

.fixed-top-menu > div {
    font-size: 1.3rem;
    color: inherit !important;
    position: relative;
    display: inline-block;
}


.navbar-brand-div {
    width: 10%;
    height: auto;
    text-align: center;
    font-size: 16px;
}

.navbar {
    padding: 0px !important;
}

.navbar-nav-2 {
    width: 100%;
}

.single-event-card {
    height: 100% !important;
}


.category_item_card:hover {
    zoom: 1.05;
}
.m-auto {
    margin: auto;
}
.w-auto {
    width: auto;
    max-width: 100%;
}


.admin-menu > div{
    background-color: inherit !important;
    border: none !important;
    font-size: 0.9rem;
    cursor: pointer;
}


.user-info {
    
    border: 0px solid rgba(0, 0, 0, 0.175);
    padding: 4px;
    margin: 2px;
    font-size: medium;
}

.btn-update-profile {
 color: white;
}

.expired-membership-text {
    color: red;
}



.badge-membership {
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4%;
    background-color: #ccc;
}

#home-wrapper {
    min-height: 720px;
    height: 60vh !important;
    padding: 0px;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/space-window.jpg') ;
    /* background-attachment: fixed; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    align-items: center;
}

@media (max-width: 768px) { 
    #home-wrapper {
        height: 100vh;
    }
}
#home-wrapper-col {
   height: auto;
}

.react-calendar {
    margin: auto;
}
  
.center {
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
} 

.vertical-center {
    position: relative;
    top: 15%
}
.home-banner-container {
    position: relative;
    max-height: 100% !important;
    padding-top: 8rem;

}

@media (max-width: 500px) {
    .home-banner-container {
        padding-top: 6rem;
    }
} 
    

.home-banner-text {
    line-height: 90px;
    color: white;
}


.invert-color {
    background-color: #fffdf8 !important;
}
.row-stn {
    margin: auto;
    padding: 4rem 0rem 0rem 0rem;
    height: auto;
}

.section-wrapper {
    padding: 1rem;
    margin: auto;
    min-height: 40vh;
    min-height: 560px;
    height: auto;
    max-width: 1200px;
    justify-content: center;
}

.events-section-wrapper {
    background-image: url('../images/galaxysplash.jpg') ; /* Replace with your image path */
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    padding: 40px 20px; /* Adds padding for better spacing */
    color: white; /* Adjust text color for better contrast */
    position: relative; /* Ensures child elements are positioned correctly */
}

/* Optional: Add an overlay for better readability */
.events-section-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 1; /* Ensures the overlay is above the background image */
}

/* Ensure content is above the overlay */
.header-section, .features-div {
    position: relative;
    z-index: 2; /* Ensures content is above the overlay */
}

.events-section-wrapper .card {
    background-color: rgba(420, 200, 300, 0.1) !important;
}

.header-section {
    margin-bottom: 20px;
}

.header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
}

.more-events-link {
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #007BFF;
    transition: color 0.3s ease;
}

.more-events-link:hover {
    color: #0056b3;
}

.features-div {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;  /* Adds space between cards */
    width: 100%;
}

@media screen and (max-width: 920px) {
    .features-div {
        flex-direction: column;  /* Changes from row (default) to column */
    }
}

.fd-mb {
    flex-grow: 1;
    width: 100% !important;
    min-width: 400px !important;
    height: 400px !important;
    border: 1px solid black;
    padding-left: 2px;
    padding-right: 2px;
}

.table-div {
    display: grid;
    grid-template-columns: auto auto;
    grid: 10px 10px;
    padding: 2px;
    width: 100% !important;
}

.table-div > div {
   width: 100%;
   height: 100%;
   border: 1px solid red;
}


.row-title {
    margin: auto;
    margin-bottom: auto !important;
    font-weight: 900 !important;
    font-family: Arial, Helvetica, sans-serif;
}
.row-csam-objectives {
    margin: auto;
    height: auto;
    padding: 3rem 0.25rem 0.25rem 0.25rem !important;
    font-family: 'Times New Roman';
}



.goals-card {
    text-align: center;
    margin: auto;
    border: none !important;
    background-color: #fcfbf9 !important;
    display: block !important;
    height: 230px !important;
    width: 100% !important;
    padding: 2rem;
    padding-top: 1rem;
    
}

.goals-col {
    padding: 1rem !important;
}
.goals-icon {
    color: #ffc720;
    margin-right: auto!important;
    margin-left: auto!important;
    max-width: 4rem;
    width: 50% !important;
    height: auto !important;
}

@media (max-width: 768px) {
    .goals-col {
        padding: 2px !important;
    }

    .goals-card {
        margin: auto;
        height: 250px !important;
        padding: 2px !important;
    } 

    .goals-body-text {
        font-size: 0.80rem !important;
    }
}
.goals-header-text {
    font-weight: bold;
    padding-top: 1rem;
    font-size: 1.25rem;
   
}
.goals-body-text {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    color: #646464;
}
.news-slide-row {
    height: 400px;
   
}

.news-div {
    height: auto;
    border: 1px solid #fffdf8;
    border-radius: 0.0375rem;
    box-shadow: 2px 2px #f4f4f4;
}


.news-col {
    padding: 20px !important;
    padding-left: 0px !important;
    margin: auto;
}
.news-card {
    height: 400px !important;
    border: none !important;
    margin-bottom: 10px;
    
}

.news-card-text {
    font-size: 0.9rem;
    line-height: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}


.news-card > .card-body {
    height: 175px;
}


.news-card:hover {
    cursor: pointer;
    border: 2px solid #f4f4f4 !important;
    padding: 2px;
}

.news-img {
    height: 230px !important;
    width: 100% !important;
    border-radius: calc(0.475rem - 1px) !important ;
}


/* 
.goals-card:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1); 
} */

.bod-card {
    border: none !important;
    height: 100%; 
    text-align: center; 
    padding: 10px;
    cursor: pointer;
}
.bod-card:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1); 
}

.news-li-group-item {
    border-radius: 0px;
    cursor: pointer;
    border-left: 0px !important;
    border-right: 0px !important;
}

.li-group-btn {
    cursor: pointer;
}

.li-group-btn:hover {
    background-color: #f8f9fa;
}
.events-menu-items {
    display: inline-block !important;
    height: 40px;
    border-radius: 0%;
    border: 1px solid grey !important;
    margin-right: 10px;
}

.events-menu-item {
    font-size: 0.8rem !important;
}
.news-li-group-item:hover {
    filter: brightness(90%);
}

.card-header {
    background-color: #fff !important;
}
.card-header-none {
    background-color: inherit !important;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}
.event-title {
    font-size: 0.85rem !important;
}
.event-body{
    height: 180px;
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-bottom: 1px !important;
    padding-top: 5px !important;
}

.event-body > div > button {
    position: absolute;
    bottom: 1rem;
}
.event-card-footer {
    font-size: 0.8rem !important;
}

.text-slides {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}
#home-wrapper-img {
    height: auto !important;
    width: 110% !important;
    margin: auto !important;
    z-index:-5;
    position: absolute;
}


.nav-link-menu {
    display: inline-block !important;
    text-align: center;
    padding: 4px 8px 0px 8px !important;
    height: 100%;
    width: 120px !important;
    font-weight: 500 !important;
    color: inherit !important;
}


.user-nav-div {
    padding: 0rem;
    color: inherit !important;
}
@media ( max-width: 991px) {
    .nav-link-menu {
        display: block !important;
        text-align: left !important;

    }
}

@media ( min-width: 992px) {
    .navx3 {
        margin-left: auto!important;

    }
}

.submenu { 
    position: relative;
    inset: 67.5px auto 0px auto;
    background-color: black;
    color: white !important;
    height: auto;
    width: 200px;
    color: inherit;
}

.show {
    display: block;
}

.form-group {
    margin-top: 8px;
}

.btn {
    border-radius: 0.5rem !important;
}
.btn-reg-form {
    width: 170px ;
    color: black !important;
}
.verify-btn {
    width: 170px ;
}

.btn-reg-form-reverse-font {
    width: 170px;
    color: black !important;
}

.accepted_cards {
    display: inline-block;
    width: 50px;
    height: 50px;
}

.img-cards {
    height: 100%;
    width: auto;
    max-width: 100%;
}

.active-menu {
    /* border-bottom: 2px solid white;
    padding-bottom: 2px; */
}
.active-card {
    border: 1px solid blue !important;
      /* Add styles for the active state */
    box-shadow: 0px 0px 10px 3px #00000029;
    transform: translateY(-5px);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.user-profile {
    height: 100%;
    width: auto;
    max-width: 100%;
}

.rounded-circle {
  border-radius: 10%; /* This makes the corners rounded */
  background-size: cover; /*to fill the circle with image */
}

.sidepanel-card {
    height: 100% !important;
    border: 0px !important;
    border-radius: 0px !important;
    border-right: 1px solid;
    background-color: #212529 !important;
}

.sidepanel-listgroup {
    border-radius: 0px !important;
    background-color: #212529 !important;
}
.sidepanel-items {
    cursor: pointer;
    padding-right: 4px;
    background-color: inherit !important;
    color: white !important;
    font-size: 0.9rem;
    border: 0px !important;
    border-radius: 0;
}

.li-event-item {
    height: 100%;
}
.li-event-item > p {
    height: 3rem;
    font-size: 1rem !important;
    text-overflow: ellipsis;
    overflow: hidden;
} 
.react-calendar__month-view__days__day--weekend {
    color: black !important;
}

.highlight-tile {
    color:red !important;
 }

 .no-highlight-tile {
    color:black !important;
 }
.sidepanel-items:hover {
    filter: brightness(80%);
}
.sidepanel-menu-title {
    background-color: inherit !important;
    color: white !important;
    border: 0px !important;
    border-radius: 0 !important;
    font-weight: inherit;

}
#opportunities-dropdown-basic-button {
    padding: 0px;
    padding-bottom: 6px;
    background: inherit !important;
    border: none;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
}

#responsive-navbar-nav {
    width: 100%;
}
@media (max-width: 991px) {
    .nav-link-menu {
        color:#ffc107 !important;
    }
    /* #opportunities-dropdown-basic-button {
        color: #ffc107;
        
    } */

    #responsive-navbar-nav {
        opacity: 0.9;
        width: 50%;

    }
}

.float-right{
    float: right;
}

.ul-footer {
    list-style: none;
    text-align: center;
    color: #fff !important;
}

.navbar-brand:hover {
    opacity: 0.9;
  }
  
  .navbar-nav-2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

.navx3 {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

.navbar-nav .dropdown-menu {
    position: absolute !important;
    left: -100px !important;
    
}
.dropdown-toggle::after {
    display: none !important;
}

#my-basic-nav-dropdown {
    padding: 0px;
    color: inherit !important;
}

.dropdown-menu[data-bs-popper] {
    left: 10px;
    top: 35px!important;
}
.ul-footer > li {
    text-decoration: none;
    color: inherit !important;
    padding: 10px;
}

.ul-footer > li:hover {
    text-decoration: underline;
}

.ul-footer > li > a {
    color: inherit;
    text-decoration: none;
}
.px-0 {
    padding: 0px !important;
}
.social-icons{
    padding-left: 4px;
    padding-right: 4px;
    font-size: 2rem;
}


/* SWIPPER CSS */
.my-swiper-slides {
    position: relative;
    margin: auto !important;
    bottom: 0 !important;
}

.my-swiper {
    height: 400px !important;
    justify-content: center;
}

.swiper-card {
    height: 320px !important;
    width: 100% !important;
}


.goals-card-text {
    font-size: 16px;
}
.card-text-swiper {
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: auto !important;
    max-width: 100% !important;
    text-align: center;
  }
  
.swiper-slide img {

    max-width: 100%;
  }
  
.navigation-wrapper {
    position: relative;
    height: 500px !important;
}

.transparent {
    background-color: rgba(255,255,255,0) !important;
    box-shadow: none !important;
    color: white !important;
}

#nav {
    --bs-gutter-x: 0rem !important;
    width: 100% !important;
    align-content: stretch;
    position: absolute;
    top: 0px;
    z-index: 2;
    height: auto;
}

.main-nav {    
    padding: 8px 4px 4px 4px !important;
    background-color: #fff;
    box-shadow:0 2px 0.75rem rgba(12,14,28,.08);
    min-block-size: 4.25rem;
    color: black;
    width: 100% !important;
    font-size: 1rem;
}
.my-nav-link {
    max-width: 100px;
    margin-right: 10px;
    height: 35px;
    margin: 2px ;
    color: inherit !important;
}
.my-nav-link a {
    text-decoration: none;

}

.my-nav-link a:hover {

    text-decoration: none;
}

.fade-in {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.slide-content {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20%;
    width: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
    margin-bottom: 0px;
    
}
.slide-text:hover {
    text-decoration: underline;
    
}
.slide-text {
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    max-height: 100%;
    
  }
  
#home-body {
    margin: auto !important;
}
.event-card {
    width: auto;
    height: 100% !important;
    border: none !important;
}

.event-card:hover {
    cursor: pointer;
    border: 1px solid #f4f4f4 !important;
    box-shadow: 0 0 2px #ccc;
}

.event-delete {
    float: inline-end;
}
.bg-black2 {
    background-color: black !important;
    display: flex   ;
    justify-content: center;
    align-items: center;
}
.event-image {
    width: 200px important;
    object-fit: fill !important;
    height: 200px !important;
    /* border: 1px solid #f4f4f4; */
    /* border-radius: 0.375rem; */
}  

.card-top-img {
    width: 100% !important;
}

.event-date {
    padding-top: 1px;
    font-size: 0.8rem;
}


.opp-card-col {
    padding-bottom: 1rem !important;
    height: auto;
}
.opp-card {
    display: block;
    padding-bottom: 2px;
    height: 300px !important;
    
}
.opp-card:hover {
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.175);
}

.opp-text {
    max-height: 50%;  /* Instead of min-height */
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;  /* Add this to show ... */
    font-size: 0.9rem;
}
.opp-title {
    font-size: 1rem !important;
}
.mw-1200 {
    max-width: 1200px !important;
}

.mw-800 {
    max-width: 800px !important;
}
/* .opp-card:hover {
    height: auto !important;
}

.opp-card:hover .opp-text {
    display: block;
} */

/* 
.opp-show-less {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}  */

.show-more {
    height: auto !important;
    display:  block !important;
} 

.userboard-card {
    min-height: 300px;
}


.cstar-intro-cards {
    height: 230px !important;
    text-align: center;
}
.cstar-intro-cards .card-title {}
.cstar-intro-cards .card-text {
    font-size: 0.9rem;

}



.membership-item {
    height: 250px !important;
  }



.mb-90 {
    margin-bottom: 90px !important;
  }

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
  }
  

.mb-100 {
    margin-bottom: 100px !important;
  }
  .btn-become-member {
    border-radius: 1% !important;
    width: 400px;
    max-width: 80%;
    font: 18rem;
  }

  .home-btn {
    margin-right: 10px !important;
    margin-left: 10px !important;
    width: 10rem !important;
    font-size: 1rem!important;
    height: 60px !important;
    color: white !important; 
    box-shadow: 0 0 10 10 #ccc ;
    font-weight: 500 !important;
  }

  body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  [class^="number-slide"],
  [class*=" number-slide"] {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    height: 300px;
    max-height: 100vh;
  }
  
  .number-slide1 {
    background: rgb(64, 175, 255);
    background: linear-gradient(
      128deg,
      rgba(64, 175, 255, 1) 0%,
      rgba(63, 97, 255, 1) 100%
    );
  }
  
  .number-slide2 {
    background: rgb(255, 75, 64);
    background: linear-gradient(
      128deg,
      rgba(255, 154, 63, 1) 0%,
      rgba(255, 75, 64, 1) 100%
    );
  }
  
  .number-slide3 {
    background: rgb(182, 255, 64);
    background: linear-gradient(
      128deg,
      rgba(182, 255, 64, 1) 0%,
      rgba(63, 255, 71, 1) 100%
    );
    background: linear-gradient(
      128deg,
      rgba(189, 255, 83, 1) 0%,
      rgba(43, 250, 82, 1) 100%
    );
  }
  
  .number-slide4 {
    background: rgb(64, 255, 242);
    background: linear-gradient(
      128deg,
      rgba(64, 255, 242, 1) 0%,
      rgba(63, 188, 255, 1) 100%
    );
  }
  
  .number-slide5 {
    background: rgb(255, 64, 156);
    background: linear-gradient(
      128deg,
      rgba(255, 64, 156, 1) 0%,
      rgba(255, 63, 63, 1) 100%
    );
  }
  .number-slide6 {
    background: rgb(64, 76, 255);
    background: linear-gradient(
      128deg,
      rgba(64, 76, 255, 1) 0%,
      rgba(174, 63, 255, 1) 100%
    );
  }
  
  .hide {
    display: none !important;
}

.row-spotlight {
    display: flex;
    margin: auto !important;
    padding: 4rem 0rem 0rem 0rem;
    height: 500px !important;
    max-width: 870px;
}

@keyframes fade {
    0%,100% { opacity: 0 }
    50% { opacity: 1 }
  }

  @keyframes fadeOut {
    from {
        opacity: 1;
    } 
    to {
        opacity: 0;
    }
  }
.spotlight-flex-container {
    display: flex;
    flex-direction: row;
}

@media (max-width: 860px) { 
    .spotlight-flex-container {
        flex-direction: column;
    }

    #main-box {
        height: 80% !important;
        margin: none !important;
    }

    #side-box-flex {
        flex-direction: row !important;
        width: 100% !important;
    }

    #side-box {
        height: 175px !important;
        width: 100% !important;
    }
}
.spotlight-hero {
    border: 0.5px solid black;
    color: white;
    margin: auto;
    background-color: rgba(0, 0, 0);
    margin-top: 4rem;
    height: 520px !important;
    max-width: 870px;
}

#main-box {
    position: relative;
    flex-grow: 8;
    height: 100%;
    font-size: 1.2rem;
}


.main-box-text {
    font-size: 0.9rem;
    color: white;
}
.hero-image {
    transition: opacity 0.5s ease-in-out;
    cursor: pointer;
    background-size: cover;
    background-position: center;
}

.fade-in-2 {
    animation: fade 6s linear;
}

.fade-out-2 {
    animation: fadeOut 6s ease-out;
}

.bottom-div {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute ;
    bottom: 0px;
    width: 100%;
    height: auto;
    padding: 0.5rem;
    padding-top: 0px;
}
.bottom-div:hover {
    text-decoration: underline;
}

.bottom-div > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.bottom-div > * {
    flex: 1;
    width: 100%;
}
.bottom-div > div > p {
    font-size: 0.9rem;
    margin: 0px;
}

#side-box {
    flex-grow: 2;
    height: 100%;
    display: flex;  
    width: 50px;  
}

#side-box-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#side-box-flex > div {
    flex: 1;
    width: 100%;
}

.mini-box > * {
    font-size: 0.7rem !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 2px;
    padding-right: 2px;
    
}


.mini-box {
    position: relative;
    height: 100%;
}


.award-card-body > h7 {
    font-weight: bold;
}

.search-bar {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}

.nav-memberstable {
    width: 90% !important;
    height: 60px;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    display: flex !important;
    justify-content: center;
}

.nav-memberstable > div {
    flex-direction: row;
    margin: auto;
}

.nav-memberstable > div > * {
    height: 40px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px !important;
}

.events-slider-arrow-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 193, 7, 0.6) !important;
    border: none !important;
    border-radius: 50% !important;
    padding: 10px !important;
    cursor: pointer !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease !important;
}

.events-slider-arrow-btn:hover {
    background: rgba(255, 193, 7) !important;
}

.events-left-arrow {
    left: -30px;
}

.events-right-arrow {
    right: -30px;
}

@media (max-width: 860px) {
    .events-slider-arrow-btn {
        display: none !important;
    }
}
.row-title > div > h5:hover {
    text-decoration: 2px underline;
    cursor: pointer;
}


.article-div {
    height: 350px !important;
    width: auto !important;
}

.article-img {
    height: inherit !important;
    width: inherit !important;
    object-fit: cover !important;
}



.nasa-featured-news {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}



.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 0.5rem;
}

.section-header h2 {
    font-size: 1.75rem;
    font-weight: 600;
    color: #0b3d91;
    margin: 0;
}
.news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 0.5rem;
}

.nasa-style-card {
    border: none;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 0.5rem;
}

.section-header h2 {
    font-size: 1.75rem;
    font-weight: 600;
    color: #0b3d91;
    margin: 0;
}

.nasa-style-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.card-image-container {
    position: relative;
    overflow: hidden;
    height: 200px;
}

.card-image-container iframe,
.card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-category {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.card-body {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.card-date {
    color: #666;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}

.card-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #222;
    line-height: 1.3;
}

.card-excerpt {
    color: #444;
    font-size: 0.9rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    flex-grow: 1;
}


.event-text {
    font-size: 0.8rem;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: auto !important;
    padding-top: 1px;
    margin-bottom: 1rem;
}

.read-more-link {
    color: #0b3d91;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
}

.read-more-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .news-grid {
        grid-template-columns: 1fr;
    }
    
    .section-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
}

